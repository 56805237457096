<template>
    <div>
        <v-progress-linear :indeterminate="true" v-if="loading"></v-progress-linear>
        <v-container v-else>
            <form @submit.prevent="add_badges" autocomplete="off">
                <v-layout row wrap>
                    <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="badges.badge_name" type="text" :label="$store.getters.lang.data.badge_name"
                            outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex>


                    <!-- <v-flex xs12 lg2 xl2 md3 sm4>
                        <v-text-field v-model="badges.badge_image" type="text"
                            :label="$store.getters.lang.data.badge_image" outlined class="mx-1" filled required rounded>
                        </v-text-field>
                    </v-flex> -->


                    <v-flex xs12 lg2 xl2 md2 sm4>
                        <v-btn rounded color="primary" type="submit" x-large>{{$store.getters.lang.data.add_btn}} badges
                        </v-btn>
                    </v-flex>
                </v-layout>
            </form>
            <v-layout row wrap mt-5>
                <v-flex xs12>
                    <v-card>
                        <v-card-title primary-title>
                            {{$store.getters.lang.data.badges}}
                            <v-spacer></v-spacer>
                            <v-text-field autocomplete="off" v-model="search" append-icon="fas fa-search">
                            </v-text-field>
                        </v-card-title>
                        <v-card-text>
                            <v-data-table :headers="headers" :search="search" :items="rows" class="elevation-0"
                                item-key="badge_id">
                                <template v-slot:[`item.badge_id`]="{ item }">
                                    <div>
                                        <v-btn icon color="error" :disabled="$store.state.auth.user.admin_role != 'admin'" class="mx-1" @click="select_badges(item)" rounded>
                                            <v-icon>mdi-delete-outline</v-icon>
                                        </v-btn>
                                        <v-btn :to="'/badges-list/'+item.badge_id" icon color="secondary" class="mx-1"
                                            rounded>
                                            <v-icon>mdi-pencil-outline</v-icon>
                                        </v-btn>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-flex>
            </v-layout>
        </v-container>
        <v-dialog v-model="delete_dialog" persistent max-width="290">
            <v-card>
                <v-card-title>
                    {{$store.getters.lang.data.delete_question}}
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="error" class="elevation-0" @click="delete_dialog = false">
                        {{$store.getters.lang.data.cancel_btn}}
                    </v-btn>
                    <v-btn color="green darken-1" text @click="delete_badges(selected_badges.badge_id)">
                        {{$store.getters.lang.data.yes_btn}}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar v-model="snackbar.value" :color="snackbar.color">
            {{snackbar.text}}
        </v-snackbar>
    </div>
</template>
<script>
    import controller from './../../controller/badges'
    export default {
        data() {
            return {
                badges: {},
                search: '',
                loading: true,
                snackbar: {
                    value: false,
                    text: '',
                    color: ''
                },
                selected_badges: {},
                delete_dialog: false,
                headers: [

                    


                    {
                        text: this.$store.getters.lang.data.badge_name,
                        align: 'start',
                        sortable: true,
                        value: 'badge_name',
                    },
                    {
                        text: '#',
                        align: 'start',
                        sortable: true,
                        value: 'badge_id',
                    },


                    // {
                    //     text: this.$store.getters.lang.data.badge_image,
                    //     align: 'start',
                    //     sortable: true,
                    //     value: 'badge_image',
                    // },


                ],
            }
        },
        computed: {
            rows() {
                return this.$store.getters.badges
            },


        },
        mounted() {
            this.read_badges();
        },
        methods: {
            add_badges() {
                controller.add_badges(this.badges).then(r => {
                    if (r.data) {
                        this.badges = {}
                        this.$store.state.badges.push(
                            r.data.new_data
                        )
                        this.snackbar = {
                            value: true,
                            text: 'badges Added',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Fail to add badges',
                            color: 'error'
                        }
                    }
                })
            },
            delete_badges(badge_id) {
                controller.delete_badges(badge_id).then(r => {
                    this.delete_dialog = false
                    if (r.data) {
                        this.$store.state.badges = this.$store.state.badges.filter(f => {
                            return f.badge_id != badge_id
                        })
                        this.snackbar = {
                            value: true,
                            text: 'badges Deleted',
                            color: 'success'
                        }
                    } else {
                        this.snackbar = {
                            value: true,
                            text: 'Delete Faild',
                            color: 'error'
                        }
                    }
                })
            },
            read_badges() {
                this.loading = true
                controller.get_badges().then(r => {
                    // this.rows = r.data.rows
                    this.loading = false
                })
            },
            select_badges(i) {
                this.selected_badges = i
                this.delete_dialog = true
            },
        },
    }
</script>